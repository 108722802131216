<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import c3api from "../../c3api";
import { useToast } from "vue-toastification";
import { LicenseManager } from "ag-grid-enterprise";
import { useRouter } from "vue-router";
import {
  saveGridState,
  loadGridState,
  resetGridFilterState,
  resetGridColState,
} from "@/utils/agGridUtils";
import { useThemeStore } from "@/stores/themeStore";
import Warehouses from '@/components/common/Warehouses.vue';
import useWarehouses from "@/hooks/useWarehouses";
const { getCachedWarehouseFilters } = useWarehouses();

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const loadCount = ref(0);
const router = useRouter();
const CLIENT_EXTERNAL_FILTERS_KEY = "clients-external-filter";
const GRID_SAVE_KEY = "clients";

let gridApi;
const toast = useToast();

const onGridReady = (params) => {
  gridApi = params.api;
  loadGridState(GRID_SAVE_KEY, gridApi, columnDefs);
};

const onStateUpdated = function (params) {
  saveGridState(GRID_SAVE_KEY, gridApi);
};

const resetGridFiltersSettings = () => {
  resetGridFilterState(GRID_SAVE_KEY, gridApi);
  router.go();
};

const resetGridColSettings = () => {
  resetGridColState(GRID_SAVE_KEY, gridApi);
  router.go();
};

const warehouseFiltersChangedCallback = function () {
  gridApi.onFilterChanged();
};

const rowDoubleClicked = function (event) {
  if (event.data.id) {
    gridApi.showLoadingOverlay();
    window.location = `/clients/${event.data.id}`;
  } else {
    console.log("Row clicked does not have an event.data.id!");
    console.log(event);
  }
};

const defaultColDef = {
  sortable: true,
  filter: "agTextColumnFilter",
  suppressHeaderMenuButton: true,
  filterParams: {
    maxNumConditions: 1,
    closeOnApply: true,
    filterOptions: ["contains"],
    buttons: ["apply"],
  },
  floatingFilter: true,
};

const columnDefs = [
  { field: "id", width: 60, headerName: "ID #", colId: "id", cellDataType: "text" },
  { field: "name", headerName: "Client", colId: "name", cellDataType: "text" },
  { field: "email", headerName: "Email", colId: "email", cellDataType: "text" },
  {
    field: "client_type.name",
    headerName: "Account Type",
    colId: "client_type.name",
    cellDataType: "text",
  },
];

const advancedFilterKeys = ["id", "name", "email", "client_type.name"];

const buildQueryUrl = function (params, csv = false) {

  let whIds = getCachedWarehouseFilters(CLIENT_EXTERNAL_FILTERS_KEY);
  if (whIds.length < 1) whIds = [1];

  const urlParams = {
    warehouses: whIds,
    sortModel: params.request.sortModel,
    startRow: params.request.startRow,
    endRow: params.request.endRow,
    sort: [
      {
        sort: "asc",
        colId: "name",
      },
    ],
    limit: 500,
  };

  const filterParams = {};
  if (params.request.filterModel) {
    for (let [k, v] of Object.entries(params.request.filterModel)) {
      if (advancedFilterKeys.includes(k)) {
        filterParams[k] = v;
      } else {
        urlParams[k] = v.filter || v.dateFrom?.substring(0, 10);
      }
    }
  }

  urlParams.filter = filterParams;
  if (typeof params.request.startRow !== "undefined") {
    urlParams.start_row = params.request.startRow;
    urlParams.limit = params.request.endRow - params.request.startRow;
  }

  if (csv === true) {
    urlParams.format = "csv";
  }

  // Handle sorting
  if (params.request.sortModel.length > 0) {
    const { colId, sort } = params.request.sortModel[0];
    urlParams.sort = colId;
    urlParams.colId = sort;
  }

  if (!csv && params.request.sortModel.length > 0) {
    const { colId, sort } = params.request.sortModel[0];
    urlParams.sort = sort;
    urlParams.colId = colId;
  }

  return urlParams;
};

const connectDatasource = {
  async getRows(params) {
    loadCount.value++;
    gridApi.showLoadingOverlay();
    const urlParams = buildQueryUrl(params);
    try {
      const response = await c3api.get("/clients", { params: urlParams });
      const data = response.data;
      console.log(data, "clients response data");
      params.success({
        rowData: data.data,
        rowCount: data.total_count,
      });
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.data.join(". "));
      params.fail();
    } finally {
      loadCount.value--;
      if (loadCount.value <= 0) {
        gridApi.hideOverlay();
      }
    }
  },
  maxConcurrentDatasourceRequests: 1,
};

const gridOptions = {
  rowModelType: "serverSide",
  serverSideDatasource: connectDatasource,
  columnDefs: columnDefs,
  cacheBlockSize: 100,
  blockLoadDebounceMillis: 500,
  autoSizeStrategy: {
    type: "fitGridWidth",
    defaultMinWidth: 100,
  },
  sideBar: {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
  dataTypeDefinitions: {
    float: {
      extendsDataType: "number",
      baseDataType: "number",
      valueFormatter: (params) =>
        params.value == null ? "0.00" : parseFloat(params.value).toFixed(2),
    },
  },
  getRowId: (params) => {
    return params.data.id;
  },
  onStateChanged: () => {
    saveGridState(GRID_SAVE_KEY, gridApi);
  },
};
</script>

<template>

  <Warehouses :callBack="warehouseFiltersChangedCallback" :parent-filter="CLIENT_EXTERNAL_FILTERS_KEY" />

  <div class="d-flex justify-end mx-4 w-full mt-4">
    <div class="align-center">
      <v-btn class="" variant="text" @click="resetGridFiltersSettings"
        >Reset Filters</v-btn
      >
      <v-btn class="mr-2" variant="text" @click="resetGridColSettings"
        >Reset Columns</v-btn
      >
    </div>
  </div>
  <AgGridVue
    style="width: 100%; height: 100%"
    :class="themeStore.agTheme"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :gridOptions="gridOptions"
    @state-updated="onStateUpdated"
    @grid-ready="onGridReady"
    @rowDoubleClicked="rowDoubleClicked"
  ></AgGridVue>
</template>
